.container {
    padding: 0 theme('container.gutters.small');
    margin-top: theme('rhythm.medium');
    margin-left: auto;
    margin-right: auto;

    @media (min-width: theme('screens.md')) {
        padding: 0 theme('container.gutters.medium');
    }

    @media (min-width: theme('screens.lg')) {
        margin-top: theme('rhythm.large');
    }
}

.orderingIsEasy {
    & > div {
        padding-bottom: 17.25rem;

        @media (min-width: theme('screens.md')) {
            padding-bottom: 10rem;
        }

        @media (min-width: theme('screens.lg')) {
            padding-bottom: theme('spacing.600');
        }
    }
}

.trustBlockContainer {
    margin: 0 auto;
    max-width: 84rem;
    padding: 0 theme('container.gutters.small');

    @media (min-width: 465px) {
        padding: 0 theme('container.gutters.large');
    }

    @media (min-width: 960px) {
        padding: 0 theme('spacing.600');
    }
}

.trustBlock {
    margin-top: -15.5rem;

    @media (min-width: theme('screens.md')) {
        margin-top: -8rem;
    }

    @media (min-width: theme('screens.lg')) {
        margin-top: -5.25rem;
    }
}
