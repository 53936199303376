.wrapper {
    display: grid;
    gap: theme('spacing.100');
    max-width: theme('container.sizes.large');
    padding: 0 theme('container.gutters.small');
    margin: theme('rhythm.medium') auto 0;

    @media (min-width: theme('screens.md')) {
        @apply grid-cols-2;

        padding: 0 theme('container.gutters.medium');
    }

    @media (min-width: theme('screens.lg')) {
        @apply grid-cols-3;

        margin-top: theme('rhythm.large');
    }
}

.link {
    @apply type-100  type-link-reverse;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: theme('spacing.100');
    fill: theme('colors.actions.secondary');
}

.callout {
    border-color: theme('colors.decorators.subtle') !important; /* Need to override the component styling here */

    @media (min-width: theme('screens.md')) {
        @apply col-span-2;
    }

    @media (min-width: theme('screens.lg')) {
        @apply col-span-3;
    }
}
